/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { MessengerChat } from 'react-messenger-chat-plugin';

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            marginTop: `3rem`,
            paddingTop: `1rem`,
            borderTop: `5px solid #006E5C`
          }}
        >
          © {new Date().getFullYear()}, Благодійна організація “Благодійний фонд “Імпульс Україна”
          {` `}
        </footer>
      </div>
      <MessengerChat
        pageId='106643974984205'
        language='uk_UA'
        themeColor={'#FDA4C8'}
      />;
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
